import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import 'react-toastify/dist/ReactToastify.css';

// import routes
import Routes from "./routes";
import { ToastContainer } from "react-toastify";

export function App() {
  return (
    <>
    <BrowserRouter basename={"/"}>
      <ScrollContext>
        <Routes />
      </ScrollContext>
    </BrowserRouter>
     <ToastContainer
     className="ui-pnotify"
     closeButton={ false }
     closeOnClick={ false }
     draggable={ false }
     position="top-right"
     hideProgressBar={ true }
     autoClose={ 3000 }
     containerId="default"
     enableMultiContainer={ true }
      />
      </>
  );
}

ReactDOM.render(<App />, document.getElementById("app"));
