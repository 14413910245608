// ** Axios Imports

import { throws } from "assert";
import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_URL;

export const login = function(payload) {
  return axios
    .post(`${API_URL}/auth/login`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
       throw error.response.data.error;
    });
};

export const forgetPassword = (payload) => {
  return axios
    .post(`${API_URL}/auth/forgetpassword`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const resetPassword = (payload) => {
  return axios
    .post(`${API_URL}/auth/resetpassword`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};
