import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Layout from "../components/layout";
import signIn from "../components/pages/others/sign-in";
import forgotPassword from "../components/pages/others/forgot-password";

let Dashboard = React.lazy(() => import("../components/pages/dashboard"));
let MediaPages = React.lazy(() => import("./media"));
let TagsEdit = React.lazy(() => import("../components/pages/tags/tags-edit"));
let CategoriesEdit = React.lazy(() =>
  import("../components/pages/categories/categories-edit")
);
let ProductsPages = React.lazy(() => import("./products"));
let PostsPages = React.lazy(() => import("./posts"));
let EcommercePages = React.lazy(() => import("./ecommerce"));
let UsersPages = React.lazy(() => import("./users"));
let MultivendorPages = React.lazy(() => import("./multivendor"));
let ElementsPages = React.lazy(() => import("./elements"));
let FormsPages = React.lazy(() => import("./forms"));
let TablesPages = React.lazy(() => import("./tables"));
let OtherPages = React.lazy(() => import("./others"));

export default function Routes() {

  const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
    console.log(authenticated)
    return (
      <Route
        {...rest}
        render={(props) => authenticated
          ? <Component {...props} />
          : <Redirect to={{pathname: '/', state: {from: props.location}}} />}
      />
    )
  }
  return (
    <React.Suspense
      fallback={
        <div className="loading-overlay">
          <div className="bounce-loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      }
    >
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={signIn} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/forgot-password`}
          component={forgotPassword}
        />
        <PrivateRoute authenticated={localStorage.getItem("token")} path={`${process.env.PUBLIC_URL}/pages`} component={OtherPages} />
        <Layout>
        <PrivateRoute authenticated={localStorage.getItem("token")} path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />
        <PrivateRoute authenticated={localStorage.getItem("token")} path={`${process.env.PUBLIC_URL}/media`} component={MediaPages} />
        <PrivateRoute authenticated={localStorage.getItem("token")} path={`${process.env.PUBLIC_URL}/products`} component={ProductsPages} />
        <PrivateRoute authenticated={localStorage.getItem("token")} path={`${process.env.PUBLIC_URL}/posts`} component={PostsPages} />
        <PrivateRoute authenticated={localStorage.getItem("token")} path={`${process.env.PUBLIC_URL}/tags/:id`} component={TagsEdit} />
        <PrivateRoute authenticated={localStorage.getItem("token")} path={`${process.env.PUBLIC_URL}/categories/:id`} component={CategoriesEdit} />
        <PrivateRoute authenticated={localStorage.getItem("token")} path={`${process.env.PUBLIC_URL}/ecommerce`} component={EcommercePages} />
        <PrivateRoute authenticated={localStorage.getItem("token")} path={`${process.env.PUBLIC_URL}/multivendor`} component={MultivendorPages} />
        <PrivateRoute authenticated={localStorage.getItem("token")} path={`${process.env.PUBLIC_URL}/users`} component={UsersPages} />
        <PrivateRoute authenticated={localStorage.getItem("token")} path={`${process.env.PUBLIC_URL}/elements`} component={ElementsPages} />
        <PrivateRoute authenticated={localStorage.getItem("token")} path={`${process.env.PUBLIC_URL}/forms`} component={FormsPages} />
        <PrivateRoute authenticated={localStorage.getItem("token")} path={`${process.env.PUBLIC_URL}/tables`} component={TablesPages} />
        </Layout>
      </Switch>
    </React.Suspense>
  );
}
